export interface OrderCustomFields {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  via?: string;
  fpr?: string;
}

export const getOrderCustomFields = (): OrderCustomFields => {
  const params = new URLSearchParams(location.search);

  const customFields = {} as OrderCustomFields;

  const allowedFields = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    'via',
    'fpr',
  ];

  for (const [key, value] of params.entries()) {
    if (allowedFields.includes(key)) {
      customFields[key] = value;
    }
  }

  return customFields;
};
