import fetch from 'isomorphic-fetch';

const api = process.env.API;

import {
  IMakePurchasePayload,
  IMakePurchaseResponseWrapper,
} from '@/src/modules/payment-page/models/purchases';
import { apiResponseParser } from '@/common/apiResponseParser';

export default function makePurchase(
  data: IMakePurchasePayload
): Promise<IMakePurchaseResponseWrapper> {
  return fetch(`${api}/purchases`, {
    method: 'post',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then(apiResponseParser);
}

interface IOrderCheckPayload {
  order_id: string;
  metadata?: object | null;
}

export function paypalOrderCheck(data: IOrderCheckPayload): Promise<any> {
  return fetch(`${api}/purchases/paypal/order-capture`, {
    method: 'post',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then(apiResponseParser);
}

export function paypalCancelOrder(data: IOrderCheckPayload): Promise<any> {
  return fetch(`${api}/purchases/paypal/cancel`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}
