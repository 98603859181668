export const isEmailValid = (email: string): boolean => {
  const emailRegex = new RegExp(/[^\s@]+@[^\s@]+\.[^\s@]+/);

  return emailRegex.test(email);
};

export const getCleanEmail = (email: string): string => {
  let cleanEmail = email.trim().toLowerCase();
  let startPos = cleanEmail.indexOf('+'),
    endPos = cleanEmail.indexOf('@');

  if (startPos < 0) {
    startPos = cleanEmail.indexOf(' ');
  }

  if (startPos > -1 && endPos > startPos) {
    return (
      cleanEmail.substring(0, startPos) + '@' + cleanEmail.substring(endPos + 1)
    );
  }
  return cleanEmail;
};
