import { useOfferStore } from '@/src/common/zustand';

const isStripeTaxEnabled = (): boolean => {
  const stripeOptions = useOfferStore
    .getState()
    .offer?.account?.integrations?.find(
      (i) => i.system_name === 'Stripe'
    )?.options;

  if (stripeOptions?.['tax_behavior'] === 'inclusive') {
    return false;
  }

  const mode =
    useOfferStore.getState().offer?.status === 'live' ? 'livemode' : 'testmode';

  return stripeOptions?.[`stripe_tax_${mode}`] ?? false;
};

export default isStripeTaxEnabled;
