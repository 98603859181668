import create from 'zustand';
import { subscribeWithSelector, devtools, persist } from 'zustand/middleware';
import { createPageSlice, createStripeSlice } from './slices';

import { PageStore } from './types';
import { shouldNotPersist } from './utils';

const usePageStore = create<PageStore>(
  subscribeWithSelector(
    persist(
      devtools(
        (set, get): PageStore => ({
          ...createStripeSlice(set),
          ...createPageSlice(set, get),
        })
      ),
      {
        name: 'voma-page-store',
        getStorage: () => sessionStorage,
        partialize: (state) =>
          Object.fromEntries(
            Object.entries(state).filter(([key]) => !shouldNotPersist([key]))
          ),
      }
    )
  )
);

export default usePageStore;
