import { useOfferStore } from '@/src/common/zustand';

import {
  getTaxAmounts,
  getStripePaymentIntegrationId,
  isTaxable,
} from './helpers';

import { CURRENCY_CODE, EMPTY_RESPONSE } from './constants';

import { LineItem, IAddress, TaxesResponse } from './types';

export const calculateOtpUpsellTax = async (
  address: IAddress
): Promise<TaxesResponse> => {
  // 1. Offer and upsell details
  const offer = useOfferStore.getState().offer!;

  // get slug parameter from url
  const query = new URLSearchParams(window.location.search);
  const offerSlug = query.get('slug')!;

  const accountSlug = offer.account.slug;
  const currency = offer.currency.code ?? CURRENCY_CODE.USD;
  const liveMode = offer.status === 'live';

  const upsell = offer.upsells?.[0];
  const upsellAmount = upsell.payment_option.unit_amount!;

  // 2. Is upsell taxable?
  const isUpsellTaxable = isTaxable(upsell?.product?.product_tax_code);

  if (!isUpsellTaxable) {
    return EMPTY_RESPONSE;
  }

  // 3. Line item for tax calculation
  let lineItems: LineItem[] = [];

  lineItems.push({
    amount: +upsellAmount.toFixed(),
    reference: `upsell_otp_${upsell.product.name}`,
    tax_code: upsell.product?.product_tax_code as string,
  });

  // 4. Assemble request body
  const payload = {
    account: accountSlug,
    integration_id: getStripePaymentIntegrationId(),
    live_mode: liveMode,
    tax: {
      currency,
      customer_details: {
        address: {
          country: address.country,
          city: address.city,
          postal_code: address.postal_code,
          state: address.state,
          line1: address.line1,
        },
        address_source: 'billing',
      },
      line_items: lineItems,
    },
    upsell: true,
  };

  // 5. Get tax amounts
  return await getTaxAmounts(offerSlug, payload);
};
