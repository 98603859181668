const handleRequest = (url: string, method: string, payload: object = {}) => {
  let options: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (method === 'POST') {
    options.body = JSON.stringify(payload);
  }

  return fetch(url, options);
};

export default handleRequest;
