import { SetState } from 'zustand';
import { PageStore, StripeSlice } from '../types';
import { SubscriptionAmounts } from '../../hooks/useSubscriptionPreview';
import { ActiveElementTypes } from '@/src/components/Checkout/Checkout.enums';
import { AddSubscriptionInvoiceItem } from '@/src/services/PurchaseService/types';

const createStripeSlice = (set: SetState<PageStore>): StripeSlice => ({
  paymentElementPurchaseHash: null,
  paymentElementClientSecret: null,
  paymentElementPaymentError: null,
  paymentElementPaymentLoading: false,
  paymentElementPaymentMethod: null,
  paymentElementLoadingMode: 'automatic',
  triggerCreateStripeToken: false,
  stripeToken: null,
  subscriptionRebills: 0,
  forcedActiveElement: null,
  addSubInvoiceItems: [],
  subscriptionAllItems: [],
  selectedPaymentOptionId: null,
  setPaymentElementPurchaseHash: (paymentElementPurchaseHash: string) =>
    set({ paymentElementPurchaseHash }),
  setPaymentElementClientSecret: (paymentElementClientSecret: string) =>
    set({ paymentElementClientSecret }),
  setPaymentElementPaymentError: (paymentElementPaymentError: object | null) =>
    set({ paymentElementPaymentError }),
  setPaymentElementLoadingMode: (paymentElementLoadingMode: string) =>
    set({ paymentElementLoadingMode }),
  setPaymentElementPaymentMethod: (paymentElementPaymentMethod: string) =>
    set({ paymentElementPaymentMethod }),
  setTriggerCreateStripeToken: (triggerCreateStripeToken: boolean) =>
    set({ triggerCreateStripeToken }),
  setStripeToken: (stripeToken) => set({ stripeToken }),
  setSubscriptionRebills: (subscriptionRebills: number) =>
    set({ subscriptionRebills }),
  setForcedActiveElement: (forcedActiveElement: ActiveElementTypes | null) =>
    set({ forcedActiveElement }),
  setAddSubInvoiceItems: (items: AddSubscriptionInvoiceItem[]) =>
    set({ addSubInvoiceItems: items }),
  setSubscriptionAllItems: (items: SubscriptionAmounts['allItems']) =>
    set({ subscriptionAllItems: items }),
  setSelectedPaymentOptionId: (selectedPaymentOptionId: number) =>
    set({
      selectedPaymentOptionId,
    }),
});

export default createStripeSlice;
