import fetch from 'isomorphic-fetch';
import LOG_LEVELS from '../logLevels';

const displayLogLevelIndex = LOG_LEVELS.indexOf(
  process.env.DISPLAYED_LOG_LEVEL!
);
const ssrHost = process.env.SSR_HOST;

export enum Level {
  trace = 'trace',
  debug = 'debug',
  info = 'info',
  warn = 'warn',
  error = 'error',
  fatal = 'fatal',
}

export enum Cause {
  api = 'api',
  server = 'server',
  default = 'default',
  ssr = 'ssr',
}

interface IWriteLogData {
  level?: Level;
  message: string;
  info?: string | object | null;
  error?: string | object | null;
}

export default function writeLog(
  { level = Level.trace, message, info, error }: IWriteLogData,
  isServer = false
) {
  const logLevel = LOG_LEVELS.indexOf(level);
  const correctError = error
    ? error instanceof Response
      ? error.clone()
      : JSON.stringify(error)
    : error;

  if (logLevel === -1) {
    throw new Error(
      `writeLog. 2 argument must contain a level field with one of the possible values ${JSON.stringify(
        LOG_LEVELS
      )}`
    );
  }
  if (logLevel >= displayLogLevelIndex) {
    return fetch(`${isServer ? ssrHost : location.origin}/logger`, {
      method: 'post',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        isServer,
        level,
        message,
        info,
        error: correctError,
      }),
    });
  }
  return Promise.resolve();
}
