import { useOfferStore } from '../common/zustand';
import { PaymentType } from '../components/Checkout/Checkout.enums';

export const isUpsellEnabled = () => {
  const upsell = useOfferStore.getState().offer?.upsells[0];
  return (
    !!upsell?.settings?.enabled &&
    (upsell.payment_option.payment_type === PaymentType.one_time_fee ||
      !!upsell.payment_option.stripe_price_id)
  );
};
