import { useOfferStore, usePageStore } from '@/src/common/zustand';

import { isZeroDecimalCurrency } from '@/src/helpers';
import { AddSubscriptionInvoiceItem, ItemType } from '../types';
import { getSelectedPaymentOption } from './getSelectedPaymentOption';

export const getMainSubInvoiceItems = (): AddSubscriptionInvoiceItem[] | [] => {
  let invoiceItems: AddSubscriptionInvoiceItem[] = [];

  const offer = useOfferStore.getState().offer!;

  // Order bump
  const bumpEnabled = !!offer?.bumps[0]?.enabled;
  const bumpSelected = usePageStore.getState().bumpSelected;

  if (bumpEnabled && bumpSelected) {
    invoiceItems.push({
      price_data: {
        currency: offer.currency.code ?? 'USD',
        product: offer.bumps[0].product.stripe_id ?? '',
        unit_amount: offer.bumps[0].payment_option.unit_amount ?? 0,
        metadata: {
          item_type: ItemType.Bump,
        },
      },
    });
  }

  const { payment_type, trial_days, trial_period_price } =
    getSelectedPaymentOption()!;

  // Subscription paid trial
  const subscriptionHasPaidTrial =
    payment_type === 'subscription' &&
    trial_days &&
    trial_days > 0 &&
    trial_period_price &&
    trial_period_price > 0;

  if (subscriptionHasPaidTrial) {
    invoiceItems.push({
      price_data: {
        currency: offer.currency.code ?? 'USD',
        product: offer.product.stripe_id ?? '',
        unit_amount:
          trial_period_price! *
            (isZeroDecimalCurrency(offer?.currency.symbol) ? 1 : 100) ?? 0,
        metadata: {
          item_type: ItemType.Main,
        },
      },
    });
  }

  return invoiceItems;
};
