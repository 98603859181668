import { GetState, SetState } from 'zustand';
import {
  DeadlineInfo,
  IOtpPrices,
  PageStore,
  TaxCalcOTPAddressEvent,
  TaxCalcOTPStatus,
  TrackingData,
} from '../types';
import {
  sendTaxCalculationRequest,
  isZeroDecimalCurrency,
  sendCartAbandonmentRequestDebounce,
} from '../utils';

import useOfferStore from '../useOfferStore';

import { PaymentType } from '@/src/components/Checkout/Checkout.enums';
import {
  isMainTaxable,
  isBumpTaxable,
  isUpsellTaxable,
  isStripeTaxEnabled,
} from '@/src/helpers';
import { IDiscountData } from '@/src/components/Checkout/Checkout.types';
import {
  applyAutomaticDiscount,
  applyOfferStackDiscount,
  isDeadlineExpired,
  triggerAfterDeadline,
} from '@/src/components/Deadline/Deadline.helpers';

const doStatePostalCodeCountryMatch = (address1, address2) => {
  return (
    address1?.state === address2?.state &&
    address1?.postal_code === address2?.postal_code &&
    address1?.country === address2?.country
  );
};

const doesFullAddressMatch = (address1, address2) => {
  return (
    doStatePostalCodeCountryMatch(address1, address2) &&
    address1?.line1 === address2?.line1 &&
    address1?.city === address2?.city
  );
};

const createPageSlice = (
  set: SetState<PageStore>,
  get: GetState<PageStore>
) => ({
  activeTemplate: false,
  bumpSelected: false,
  showUpsell: false,
  showThankyouPage: false,
  contactInformation: {},
  contactInformationValues: {
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
  },
  addressState: {
    isValid: false,
  },
  addressValues: {
    line1: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
  },
  termsAndConditionsState: {},
  termsAndConditionsValues: {},
  triggerFormValidation: false,
  isProcessingPayment: false,
  discountCode: null,
  discount: null,
  discountProducts: null,
  couponState: null,
  ipAddress: null,
  inEU: false,
  showPreloader: false,
  gtmFbEventsData: null,
  clearCardElement: false,
  previewMode: false,
  purchaseError: null,
  deadlineExpired: false,
  deadlineInfo: null,
  taxCalcOTP: {
    status: TaxCalcOTPStatus.DEFAULT,
    main_tax: null,
    bump_tax: null,
    postal_code: null,
    country: null,
    state: null,
    tax_calculation_id: null,
    message: null,
  },
  lastTaxCalcRequestAddress: {
    line1: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
  },
  setActiveTemplate: (activeTemplate) => set({ activeTemplate }),
  setBumpSelected: (bumpSelected: boolean) => {
    set({ bumpSelected });
    get().sendTaxCalcOTPEvent({
      type: 'bump',
    });
  },
  setShowUpsell: (showUpsell: boolean) => set({ showUpsell }),
  setShowThankyouPage: (showThankyouPage: boolean) => set({ showThankyouPage }),
  updateContactInformation: (formState) =>
    set({ contactInformation: formState }),
  updateContactInformationValues: (formValues) => {
    const currentValues = get().contactInformationValues;
    const oldEmail = currentValues?.email ?? null;

    set({ contactInformationValues: { ...currentValues, ...formValues } });

    const newEmail = formValues.email;
    if (newEmail !== oldEmail) {
      sendCartAbandonmentRequestDebounce(newEmail);
    }
  },
  updateAddressState: (formState) => set({ addressState: formState }),
  updateAddressValues: (formValues) => {
    const oldAddress = get().addressValues;
    set({ addressValues: formValues });

    get().sendTaxCalcOTPEvent({
      type: 'address',
      old: oldAddress,
      new: formValues,
    } as TaxCalcOTPAddressEvent);
  },
  updateTermsAndConditionsState: (formState) =>
    set({ termsAndConditionsState: formState }),
  updateTermsAndConditionsValues: (formValues) =>
    set({ termsAndConditionsValues: formValues }),
  setTriggerFormValidation: (triggerFormValidation) =>
    set({ triggerFormValidation }),
  setIsProcessingPayment: (isProcessingPayment) => set({ isProcessingPayment }),
  setDiscountCode: (discountCode) => {
    set({ discountCode });
    get().sendTaxCalcOTPEvent({
      type: 'discount',
    });
  },
  setDiscount: (discount) => set({ discount }),
  setCouponState: (couponState) => {
    set({ couponState });
  },
  setDiscountProducts: (discountProducts) => set({ discountProducts }),
  setIpAddress: (ipAddress) => set({ ipAddress }),
  setInEU: (inEU) => set({ inEU }),
  setShowPreloader: (showPreloader: boolean) => set({ showPreloader }),
  setPreviewMode: (previewMode: boolean) => set({ previewMode }),
  setGtmFbEventsData: (data: TrackingData | null) =>
    set({ gtmFbEventsData: data }),
  setClearCardElement: (val) => set({ clearCardElement: val }),
  resetContactAndAddressValues: () =>
    set({
      addressValues: {
        line1: '',
        city: '',
        state: '',
        postal_code: '',
        country: '',
      },
      contactInformationValues: {
        email: '',
        first_name: '',
        last_name: '',
        phone: '',
      },
    }),
  setPurchaseError: (purchaseError: string | null) => set({ purchaseError }),
  setDeadlineExpired: (deadlineExpired: boolean) => {
    set({ deadlineExpired });

    if (deadlineExpired) {
      const discountCode = get().discountCode;

      if (discountCode?.toLowerCase().includes('os-')) {
        set({
          discount: null,
          discountCode: null,
          discountProducts: null,
        });
      }

      triggerAfterDeadline();
    }
  },
  setDeadlineInfo: async (deadlineInfo: DeadlineInfo | null) => {
    set({ deadlineInfo });

    if (deadlineInfo?.deadlineUtc) {
      const deadlineExpired = isDeadlineExpired(deadlineInfo.deadlineUtc);

      if (deadlineExpired) {
        get().setDeadlineExpired(true);
      }
    }
  },
  doesAddressMatchLastTaxCalcRequest: (newRequestAddress) => {
    return doesFullAddressMatch(
      newRequestAddress,
      get().lastTaxCalcRequestAddress
    );
  },
  shouldSendTaxCalculationRequest: (event) => {
    const stripeTaxEnabled = isStripeTaxEnabled();

    const mainProductType = useOfferStore
      .getState()
      .offer?.payment_options?.find(
        (option) => option.id === get().selectedPaymentOptionId
      )?.payment_type;

    return (
      stripeTaxEnabled &&
      (isMainTaxable() || isBumpTaxable()) &&
      mainProductType === PaymentType.one_time_fee &&
      get().addressState.isValid &&
      ((get().taxCalcOTP.status === 'default' && event.type !== 'address') ||
        (get().taxCalcOTP.status === 'default' &&
          event.type === 'address' &&
          !get().doesAddressMatchLastTaxCalcRequest(event.new)) ||
        (event.type === 'address' &&
          !doStatePostalCodeCountryMatch(event.old, event.new) &&
          !get().doesAddressMatchLastTaxCalcRequest(event.new)) ||
        event.type === 'discount' ||
        (event.type === 'bump' &&
          (get().addressValues?.postal_code !== get().taxCalcOTP.postal_code ||
            get().addressValues?.country !== get().taxCalcOTP.country ||
            get().addressValues?.state !== get().taxCalcOTP.state)))
    );
  },
  isValidTaxCalculationResponse: (response) => {
    const currentAddress = get().addressValues;

    const taxResponseAddress = {
      postal_code: response.postal_code,
      country: response.country,
      state: response.state,
    };

    return (
      response.status === TaxCalcOTPStatus.RESPONSE &&
      typeof response.main_tax === 'number' &&
      typeof response.bump_tax === 'number' &&
      doStatePostalCodeCountryMatch(currentAddress, taxResponseAddress) &&
      response.tax_calculation_id.includes('taxcalc_')
    );
  },
  applyAutomaticDiscount: async () => {
    const currentDiscount = get().discount;

    if (currentDiscount) {
      return;
    }

    const discount = await applyAutomaticDiscount();

    if (discount && discount?.products) {
      set({
        discount,
        discountCode: `OS-${useOfferStore.getState().offer?.id}-DISCOUNT`,
        discountProducts: discount.products,
      });
    }
  },
  applyOfferStackDiscount: async () => {
    const currentDiscount = get().discount;

    if (currentDiscount) {
      return;
    }

    const discount = await applyOfferStackDiscount();

    if (discount && discount?.products) {
      set({
        discount,
        discountCode: `OS-${useOfferStore.getState().offer?.id}-DISCOUNT`,
        discountProducts: discount.products,
      });
    }
  },
  sendTaxCalcOTPEvent: async (event) => {
    if (get().shouldSendTaxCalculationRequest(event)) {
      if (
        get().taxCalcOTP.status === TaxCalcOTPStatus.ERROR &&
        get().paymentElementPaymentError
      ) {
        set({
          paymentElementPaymentError: null,
        });
      }

      set({
        taxCalcOTP: {
          status: TaxCalcOTPStatus.LOADING,
          main_tax: null,
          bump_tax: null,
          postal_code: null,
          country: null,
          state: null,
          tax_calculation_id: null,
          message: null,
        },
      });

      if (event.type === 'address') {
        set({
          lastTaxCalcRequestAddress: {
            ...event.new,
          },
        });
      }

      const taxCalculationResponse = await sendTaxCalculationRequest();

      if (
        taxCalculationResponse?.status === TaxCalcOTPStatus.RESPONSE &&
        get().isValidTaxCalculationResponse(taxCalculationResponse)
      ) {
        set({
          taxCalcOTP: {
            ...taxCalculationResponse,
            status: TaxCalcOTPStatus.VALID,
          },
        });
      } else if (taxCalculationResponse.status === TaxCalcOTPStatus.ERROR) {
        set({
          taxCalcOTP: {
            ...taxCalculationResponse,
            status: TaxCalcOTPStatus.ERROR,
          },
        });
      }
    }
  },
  isValidFinalTaxCalculation: (): boolean => {
    const stripeTaxEnabled = isStripeTaxEnabled();

    const mainProductType = useOfferStore
      .getState()
      .offer?.payment_options.find(
        (option) => option.id === get().selectedPaymentOptionId
      )?.payment_type;

    if (
      !stripeTaxEnabled ||
      (!isMainTaxable() && !isBumpTaxable()) ||
      mainProductType === PaymentType.subscription
    ) {
      return true;
    }

    if (mainProductType === PaymentType.one_time_fee) {
      const taxCalcOtp = get().taxCalcOTP;

      if (
        ![TaxCalcOTPStatus.DEFAULT, TaxCalcOTPStatus.VALID].includes(
          taxCalcOtp.status
        )
      ) {
        return false;
      }

      const testResponse = {
        ...taxCalcOtp,
        status: TaxCalcOTPStatus.RESPONSE,
      };

      if (
        taxCalcOtp.status === TaxCalcOTPStatus.VALID &&
        !get().isValidTaxCalculationResponse(testResponse)
      ) {
        return false;
      }

      return true;
    }

    return false;
  },
  canOrderBeSubmitted: (): boolean | undefined => {
    const contactInfoValid = get().contactInformation?.isValid;
    const addressValid = get().addressState?.isValid;
    const termsConditionsValid = get().termsAndConditionsState?.isValid;
    const state = useOfferStore.getState();

    const StripeTaxKey =
      state?.offer?.status === 'live'
        ? 'stripe_tax_livemode'
        : 'stripe_tax_testmode';

    const addressEnabled =
      state.offer?.customization.requested_fields.customers_address != 'no' ||
      (state.offer?.account.integrations.find((i) => i.system_name === 'Stripe')?.options?.[StripeTaxKey] === true &&
        (isMainTaxable() || isBumpTaxable() || isUpsellTaxable()));

    const termsConditionsEnabled =
      state.offer?.customization?.terms_and_conditions
        ?.enabled || false;

    return (
      contactInfoValid &&
      (!termsConditionsEnabled || termsConditionsValid) &&
      (!addressEnabled || addressValid) &&
      get().isValidFinalTaxCalculation()
    );
  },
  getOtpPrices: (): IOtpPrices | null => {
    // NON-ZERO-DECIMAL EXAMPLE (i.e. USD)
    //   {
    //     "bumpEnabled": true,
    //     "bumpSelected": true,
    //     "bumpPrice": 13,
    //     "bumpUnitAmount": 1300,
    //     "mainPrice": 12,
    //     "mainUnitAmount": 1200,
    //     "discountAmount": 500,
    //     "discountPrice": 5,
    //     "currencyCode": "USD",
    //     "currencySymbol": "$",
    //     "isZeroDecimalCurrency": false
    //  }

    // ZERO-DECIMAL EXAMPLE (i.e. JPY)
    //   {
    //     "bumpEnabled": true,
    //     "bumpSelected": true,
    //     "bumpPrice": 13000,
    //     "bumpUnitAmount": 13000,
    //     "mainPrice": 1000,
    //     "mainUnitAmount": 1000,
    //     "discountPrice": 220,
    //     "discountUnitAmount": 220,
    //     "currencyCode": "JPY",
    //     "currencySymbol": "¥",
    //     "isZeroDecimalCurrency": true
    // }

    const payment_page = useOfferStore.getState().offer;

    if (payment_page === null) {
      return null;
    }

    const currencyCode = payment_page?.currency?.code ?? 'USD';
    const zeroDecimalCurrency = isZeroDecimalCurrency(currencyCode);
    const mainPrice = payment_page.payment_options.find(
      (option) => option.id === get().selectedPaymentOptionId
    )?.price;
    const mainUnitAmount = payment_page.payment_options.find(
      (option) => option.id === get().selectedPaymentOptionId
    )?.unit_amount as number;

    let otpPrices = {
      bumpEnabled: payment_page?.bumps?.[0]?.enabled ?? false,
      bumpSelected: get().bumpSelected,
      bumpPrice: payment_page?.bumps?.[0]?.payment_option?.price ?? null,
      bumpUnitAmount:
        payment_page.bumps?.[0]?.payment_option?.unit_amount ?? null,
      mainPrice: mainPrice!,
      mainUnitAmount,
      discountPrice: 0,
      discountUnitAmount: 0,
      currencyCode: payment_page?.currency?.code ?? 'USD',
      currencySymbol: payment_page?.currency?.symbol ?? '$',
      isZeroDecimalCurrency: zeroDecimalCurrency,
    };

    const discount = get().discount;

    let discount_type: null | IDiscountData['discount_type'] = null,
      rawDiscount = 0,
      discountPrice = 0,
      discountUnitAmount = 0;

    if (discount && discount?.discount_type && discount?.discount) {
      discount_type = discount.discount_type;
      rawDiscount = Number(discount.discount);
    }

    if (
      discount_type === 'percentage' &&
      rawDiscount > 0 &&
      rawDiscount < 100
    ) {
      discountUnitAmount = Math.round((rawDiscount / 100) * mainUnitAmount);
      discountPrice = zeroDecimalCurrency
        ? discountUnitAmount
        : discountUnitAmount / 100;
    }

    if (discount_type === 'flat' && rawDiscount > 0) {
      if (zeroDecimalCurrency) {
        discountUnitAmount = rawDiscount;
        discountPrice = rawDiscount;
      } else {
        discountUnitAmount = rawDiscount * 100;
        discountPrice = rawDiscount;
      }
    }

    if (discountUnitAmount > mainUnitAmount) {
      discountUnitAmount = 0;
      discountPrice = 0;
    }

    otpPrices.discountUnitAmount = discountUnitAmount;
    otpPrices.discountPrice = discountPrice;

    return otpPrices;
  },
});

export default createPageSlice;
