import { useOfferStore } from '@/src/common/zustand';

const getStripePaymentIntegrationId = (): number | undefined => {
  const { offer } = useOfferStore.getState();

  return offer?.payment_integrations.find((i) => i.system_name === 'Stripe')
    ?.id;
};

export default getStripePaymentIntegrationId;
