import { isTaxable } from '../services/TaxService/helpers';
import { useOfferStore } from '../common/zustand';

import { isBumpEnabled } from './isBumpEnabled';

export const isBumpTaxable = () =>
  isBumpEnabled() &&
  isTaxable(
    useOfferStore.getState().offer?.bumps[0]?.product?.product_tax_code
  );
