import fetch from 'isomorphic-fetch';

import { apiResponseParser } from '@/common/apiResponseParser';
import { IDiscountData } from '../../modules/payment-page/models/elements/coupon';

export default (
  accountSlug: string,
  code: string,
  payload: object
): Promise<IDiscountData> =>
  fetch(`/api/coupon/${accountSlug}/${code}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }).then(apiResponseParser);
