import { Offer } from '@/src/pages/[host]/[uid]/[slug]';
import { PurchasePayload } from '@/src/components/Checkout/Checkout.types';
import { PaymentType } from '@/src/components/Checkout/Checkout.enums';
import { getSelectedPaymentOption } from '../services/PurchaseService/helpers';

type EventParams = {
  integrations: Offer['account']['integrations'] | null;
  paymentPage: Omit<Offer, 'account'>;
  purchasePayload?: PurchasePayload | null;
};

const triggerGtmAndFbEventsForPayPalPurchase = ({
  integrations,
  paymentPage,
  purchasePayload,
}: EventParams) => {
  const { product } = paymentPage;

  const paymentOption = getSelectedPaymentOption();

  // FB Pixel events
  const fb = integrations?.find((i) => i.system_name === 'Facebook');
  if (typeof window.fbq === 'function' && fb?.options['events_enabled']) {
    const oneTime: Array<{
      id: number;
      quantity: number;
      price: number;
    }> = [];

    let oneTimePrice = 0;
    if (paymentOption?.payment_type === PaymentType.subscription) {
      const { price, trial_days, trial_period_price } = paymentOption!;
      if (+(trial_days ?? 0) > 0 && +(trial_period_price ?? 0) === 0)
        window.fbq('track', 'StartTrial', { value: price, currency: 'USD' });
      if (+(trial_days ?? 0) === 0)
        window.fbq('track', 'Subscribe', { value: price, currency: 'USD' });
    } else {
      oneTimePrice += +(paymentOption?.price ?? 0);
      oneTime.push({
        id: product.id,
        quantity: 1,
        price: paymentOption?.price!,
      });
    }
    if (oneTime.length > 0)
      window.fbq('track', 'Purchase', {
        value: oneTimePrice,
        currency: 'USD',
        contents: oneTime,
      });
  }

  // GTM events
  if (window.dataLayer) {
    const items = [
      {
        item_name: product.name,
        item_id: product.id,
        price: paymentOption?.price,
        quantity: 1,
      },
    ];

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        currency: 'USD',
        voma_email: purchasePayload?.email,
        ...(purchasePayload?.['coupon_code'] && {
          coupon: purchasePayload?.['coupon_code'],
        }),
        items,
      },
    });
  }
};

export default triggerGtmAndFbEventsForPayPalPurchase;
