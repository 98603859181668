export interface PurchaseError {
  error: boolean;
  code?: string;
  message: string;
}

export enum ItemType {
  Main = 'main',
  Bump = 'bump',
  Upsell = 'upsell',
}

export interface AddSubscriptionInvoiceItem {
  price_data: {
    currency: string;
    unit_amount: number;
    product?: string | undefined;
    metadata?: {
      item_type: ItemType;
    };
  };
}
