import { ActiveTemplateEnum } from '@/src/components/Layout/Layout.enums';
import {
  CouponState,
  IDiscountData,
} from '../../modules/payment-page/models/elements/coupon';
import { SubscriptionAmounts } from '../hooks/useSubscriptionPreview';
import { Offer } from '@/src/pages/[host]/[uid]/[slug]';
import {
  IAddress,
  IContactInformation,
  PurchasePayload,
} from '@/src/components/Checkout/Checkout.types';
import { ActiveElementTypes } from '@/src/components/Checkout/Checkout.enums';
import { AddSubscriptionInvoiceItem } from '@/src/services/PurchaseService/types';

export type PaymentResult = {
  customerId: string;
  cartSession: any;
};

export type StripeSlice = {
  paymentElementPurchaseHash?: string | null;
  paymentElementClientSecret: string | null;
  paymentElementPaymentLoading: boolean;
  paymentElementPaymentError: object | null;
  paymentElementLoadingMode: string;
  paymentElementPaymentMethod: string | null;
  triggerCreateStripeToken: boolean;
  stripeToken: string | null;
  subscriptionRebills: number;
  forcedActiveElement: ActiveElementTypes | null;
  addSubInvoiceItems: AddSubscriptionInvoiceItem[] | [];
  subscriptionAllItems: SubscriptionAmounts['allItems'];
  selectedPaymentOptionId: number | null;
  setPaymentElementPurchaseHash: (paymentElementPurchaseHash: string) => void;
  setPaymentElementClientSecret: (paymentElementClientSecret: string) => void;
  setPaymentElementPaymentMethod: (paymentElementPaymentMethod: string) => void;
  setPaymentElementPaymentError: (
    paymentElementPaymentError: object | null
  ) => void;
  setPaymentElementLoadingMode: (paymentElementLoadingMode: string) => void;
  setTriggerCreateStripeToken: (triggerCreateStripeToken: boolean) => void;
  setStripeToken: (stripeToken: string) => void;
  setSubscriptionRebills: (subscriptionRebills: number) => void;
  setForcedActiveElement: (
    forcedActiveElement: ActiveElementTypes | null
  ) => void;
  setAddSubInvoiceItems: (items: AddSubscriptionInvoiceItem[] | []) => void;
  setSubscriptionAllItems: (items: SubscriptionAmounts['allItems']) => void;
  setSelectedPaymentOptionId: (selectedPaymentOptionId: number) => void;
};

export type DeadlineInfo = {
  deadlineUtc?: string;
  email?: string;
  expired?: boolean;
};
interface TaxCalcOTPEvent {
  type: 'address' | 'discount' | 'bump';
}

export enum TaxCalcOTPStatus {
  DEFAULT = 'default',
  VALID = 'valid',
  RESPONSE = 'response',
  LOADING = 'loading',
  ERROR = 'error',
}
export interface TaxCalcOTP {
  status: TaxCalcOTPStatus;
  main_tax: number | null;
  bump_tax: number | null;
  postal_code: string | null;
  country: string | null;
  state: string | null;
  tax_calculation_id: string | null;
  message?: string | null;
}
export interface TaxCalcOTPAddressEvent extends TaxCalcOTPEvent {
  old: IAddress;
  new: IAddress;
}

export type TrackingData = {
  purchasePayload: PurchasePayload | null;
  props: {
    paymentPage: Omit<Offer, 'account'> | null;
    thankYouPageUrl: string;
    integrations: Offer['account']['integrations'];
  };
};

export interface IOtpPrices {
  bumpEnabled: boolean;
  bumpSelected: boolean;
  bumpPrice: number | null;
  bumpUnitAmount: number | null;
  mainPrice: number;
  mainUnitAmount: number;
  discountPrice: number;
  discountUnitAmount: number;
  currencyCode: string;
  currencySymbol: string;
  isZeroDecimalCurrency: boolean;
}

export type PageSlice = {
  activeTemplate: ActiveTemplateEnum | boolean;
  bumpSelected: boolean;
  showUpsell: boolean;
  showThankyouPage: boolean;
  contactInformation: { isValid?: boolean };
  contactInformationValues: IContactInformation | null;
  addressState: { isValid: boolean };
  addressValues: IAddress | null;
  termsAndConditionsState: { isValid?: boolean };
  termsAndConditionsValues: { gdpr?: boolean; email?: string };
  triggerFormValidation: boolean;
  isProcessingPayment: boolean;
  discountCode: string | null;
  discount: IDiscountData | null;
  discountProducts: Array<number> | null;
  couponState: CouponState | null;
  ipAddress: string | null;
  inEU: boolean;
  showPreloader: boolean;
  gtmFbEventsData: TrackingData | null;
  clearCardElement: boolean;
  previewMode: boolean;
  purchaseError: string | null;
  deadlineExpired: boolean;
  deadlineInfo: DeadlineInfo | null;
  taxCalcOTP: TaxCalcOTP;
  lastTaxCalcRequestAddress: IAddress;
  setActiveTemplate: (activeTemplate: ActiveTemplateEnum) => void;
  setBumpSelected: (bumpSelected: boolean) => void;
  setShowUpsell: (showUpsell: boolean) => void;
  setShowThankyouPage: (showThankyouPage: boolean) => void;
  updateContactInformation: (formState: object) => void;
  updateContactInformationValues: (formValues: object) => void;
  updateAddressState: (formState: { isValid: boolean }) => void;
  updateAddressValues: (formValues: object) => void;
  updateTermsAndConditionsState: (formState: object) => void;
  updateTermsAndConditionsValues: (formValues: { email?: string }) => void;
  setTriggerFormValidation: (triggerFormValidation: boolean) => void;
  setIsProcessingPayment: (isProcessingPayment: boolean) => void;
  setCouponState: (couponState: CouponState | null) => void;
  setDiscountCode: (discountCode: string | null) => void;
  setDiscount: (discount: IDiscountData | null) => void;
  setDiscountProducts: (discountProducts: Array<number> | null) => void;
  setIpAddress: (ipAddress: string) => void;
  setInEU: (inEU: boolean) => void;
  setShowPreloader: (showPreloader: boolean) => void;
  setPreviewMode: (previewMode: boolean) => void;
  setGtmFbEventsData: (data: TrackingData | null) => void;
  resetContactAndAddressValues: () => void;
  setClearCardElement: (val: boolean) => void;
  setPurchaseError: (purchaseError: string | null) => void;
  setDeadlineExpired: (deadlineExpired: boolean) => void;
  setDeadlineInfo: (deadlineInfo: DeadlineInfo | null) => void;
  doesAddressMatchLastTaxCalcRequest: (address: IAddress) => boolean;
  shouldSendTaxCalculationRequest: (event: TaxCalcOTPEvent) => boolean;
  isValidTaxCalculationResponse: (response: TaxCalcOTP) => boolean;
  sendTaxCalcOTPEvent: (event: TaxCalcOTPEvent) => void;
  applyAutomaticDiscount: () => void;
  applyOfferStackDiscount: () => void;
  isValidFinalTaxCalculation: () => boolean;
  canOrderBeSubmitted: () => boolean | undefined;
  getOtpPrices: () => IOtpPrices | null;
};

export type PageStore = StripeSlice & PageSlice;
