export const CURRENCY_CODE = {
  USD: 'USD',
  EUR: 'EUR',
  GBP: 'GBP',
  CAD: 'CAD',
  SEK: 'SEK',
  TRY: 'TRY',
  AUD: 'AUD',
  NZD: 'NZD',
  JPY: 'JPY',
} as const;

export const CURRENCY_SYMBOL = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  CAD: '$',
  SEK: 'kr',
  TRY: '₺',
  AUD: '$',
  NZD: '$',
  JPY: '¥',
} as const;

export const GDPR = {
  HIDDEN: 'hidden',
  EU: 'eu',
  ALL: 'all',
} as const;

export const PAYMENT_TYPE = {
  subscription: 'subscription',
  otp: 'one_time_fee',
} as const;
