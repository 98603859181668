import create from 'zustand';
import { devtools } from 'zustand/middleware';
import produce from 'immer';

type PriceSegment = 'amount' | 'tax';

type Price = Record<PriceSegment, number>;

export interface PricesStore {
  loading: boolean;
  taxCalculationId: string;
  todayTotal: number;
  discount: number;
  tax: number;
  main: {
    otp: Price;
    subscription: Price & {
      trial: Price & { discount: number };
      discount: number;
    };
  };
  bump: Price;
  upsell: {
    otp: Price;
    subscription: Price & { trial: Price };
  };
  latest_preview?: {
    postal_code: string;
    state: string;
    country: string;
    error: boolean;
  };
  setPrices: (
    segment: PriceSegment,
    payload: PricesStorePayload,
    defaults?: boolean
  ) => void;
  setTaxCalculationId: (id: string) => void;
  setTodayTotal: (todayTotal: number) => void;
  setDiscount: (discount: number) => void;
  setTax: (tax: number) => void;
  setSubDiscount: (discount: number) => void;
  setSubTax: (tax: number) => void;
  setTrialTax: (tax: number) => void;
  setLoading: (loading: boolean) => void;
  setLatestPreview: (latest_preview: PricesStore['latest_preview']) => void;
}

export type PricesStorePayload = {
  mainPrices?: {
    otp?: number;
    subscription?: {
      amount: number;
      trialAmount?: number;
    };
  };
  bumpPrice?: number;
  upsellPrices?: {
    otp?: number;
    subscription?: {
      amount: number;
      trialAmount?: number;
    };
  };
};

export const initialPricesStore: Omit<
  PricesStore,
  | 'setPrices'
  | 'setTaxCalculationId'
  | 'setTodayTotal'
  | 'setDiscount'
  | 'setTax'
  | 'setSubDiscount'
  | 'setSubTax'
  | 'setTrialTax'
  | 'setLoading'
  | 'setLatestPreview'
> = {
  loading: false,
  taxCalculationId: '',
  discount: 0,
  tax: 0,
  todayTotal: 0,
  main: {
    otp: {
      amount: 0,
      tax: 0,
    },
    subscription: {
      trial: {
        amount: 0,
        discount: 0,
        tax: 0,
      },
      amount: 0,
      discount: 0,
      tax: 0,
    },
  },
  bump: {
    amount: 0,
    tax: 0,
  },
  upsell: {
    otp: {
      amount: 0,
      tax: 0,
    },
    subscription: {
      trial: {
        amount: 0,
        tax: 0,
      },
      amount: 0,
      tax: 0,
    },
  },
};

const usePricesStore = create<PricesStore>(
  devtools((set, get) => ({
    ...initialPricesStore,
    setPrices: (segment: PriceSegment, payload: PricesStorePayload) => {
      const { mainPrices, bumpPrice, upsellPrices } = payload;

      const newPrices = produce(get(), (draft) => {
        if (mainPrices && mainPrices.hasOwnProperty('otp')) {
          draft.main.otp[segment] = mainPrices.otp!;
        }

        if (mainPrices && mainPrices.hasOwnProperty('subscription')) {
          draft.main.subscription[segment] = mainPrices.subscription?.amount!;
          if (Number(mainPrices.subscription?.trialAmount) > 0) {
            draft.main.subscription.trial[segment] =
              mainPrices.subscription?.trialAmount!;
          }
        }

        if (bumpPrice) {
          draft.bump[segment] = bumpPrice;
        }

        if (upsellPrices?.otp) {
          draft.upsell.otp[segment] = upsellPrices.otp;
        }

        if (upsellPrices?.subscription) {
          draft.upsell.subscription[segment] = upsellPrices.subscription.amount;
        }
      });

      set(newPrices);
    },
    setTaxCalculationId: (id: string) => set({ taxCalculationId: id }),
    setTodayTotal: (todayTotal: number) => set({ todayTotal }),
    setDiscount: (discount: number) => set({ discount }),
    setTax: (tax: number) => set({ tax }),
    setSubDiscount: (discount: number) =>
      set(
        produce(get(), (draft) => {
          draft.main.subscription.discount = discount;
        })
      ),
    setSubTax: (tax: number) =>
      set(
        produce(get(), (draft) => {
          draft.main.subscription.tax = tax;
        })
      ),
    setTrialTax: (tax: number) =>
      set(
        produce(get(), (draft) => {
          draft.main.subscription.trial.tax = tax;
        })
      ),
    setLoading: (loading) => set({ loading }),
    setLatestPreview: (latest_preview) => {
      set({ latest_preview });
    },
  }))
);

export default usePricesStore;
