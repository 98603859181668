import { TaxesResponse } from '@/src/services/TaxService/types';
import { usePageStore } from '../../zustand';
import * as TaxService from '@/src/services/TaxService';

import { TaxCalcOTP, TaxCalcOTPStatus } from '../types';

const sendTaxCalculationRequest = async (): Promise<TaxCalcOTP> => {
  const addressState = usePageStore.getState().addressState;
  const addressValues = usePageStore.getState().addressValues;

  if (!addressState.isValid || !addressValues?.postal_code) {
    throw new Error('Address state or values invalid');
  }

  try {
    const {
      id,
      line_items,
      customer_details,
      message = '',
    }: TaxesResponse = await TaxService.calculateTax(addressValues);

    if (message.includes('customer_tax_location_invalid')) {
      throw new Error('customer_tax_location_invalid');
    }

    const { address } = customer_details;

    if (
      !(id && line_items.data.length && address?.postal_code && address.country)
    ) {
      throw new Error('Invalid tax calculation response');
    }

    const taxedItems = line_items.data.map(({ amount_tax, reference }) => ({
      amount_tax,
      reference,
    }));

    const getTax = (ref: string) =>
      taxedItems.find((item) => item.reference.includes(ref))?.amount_tax ?? 0;

    const result = {
      status: TaxCalcOTPStatus.RESPONSE,
      main_tax: getTax('main_product_otp'),
      bump_tax: getTax('bump_otp'),
      postal_code: address.postal_code,
      country: address.country,
      state: address.state || '',
      tax_calculation_id: id,
    };

    return result;
  } catch (error) {
    // console.log('Error in sendTaxCalculationRequest', error.message);
    const result = {
      status: TaxCalcOTPStatus.ERROR,
      message: error.message || '',
      main_tax: null,
      bump_tax: null,
      postal_code: null,
      country: null,
      state: null,
      tax_calculation_id: null,
    };

    return result;
  }
};

export default sendTaxCalculationRequest;
