import { handleRequest } from '../../helpers';

let proxyBase = '/api/psproxy';
// Required for Jest tests - otherwise we see "only absolute urls are supported" error from fetch
if (process.env.CI_TEST) {
  proxyBase = 'http://localhost:3000/api/psproxy';
}

const logError = (error) => {
  console.error('There has been a problem with your fetch operation:', error);
};

const getTaxAmounts = async (offerSlug: string, payload: object) =>
  await handleRequest(
    `${proxyBase}/${offerSlug}/stripe/tax/calculations`,
    'POST',
    payload
  )
    .then((data) => data.json())
    .catch(logError);

export default getTaxAmounts;
