import { components } from '@/src/schemas/offer';
import { IAddress } from './types';
import { PaymentType } from '@/src/components/Checkout/Checkout.enums';
import {
  useOfferStore,
  usePageStore,
  usePricesStore,
} from '@/src/common/zustand';
import { CURRENCY_CODE } from './constants';
import {
  getStripePaymentIntegrationId,
  getTaxAmounts,
  isTaxable,
} from './helpers';
import { getPageSlug } from '@/src/components/Checkout/Checkout.utils';
import { getSelectedPaymentOption } from '../PurchaseService/helpers';

export type LineItem = {
  amount: number;
  reference: string;
  tax_code: string | null;
};

type LineItemRes = {
  amount_tax: number;
  reference: string;
};

export type TaxesResponse = {
  message?: string;
  id: string;
  line_items: {
    data: LineItemRes[];
  };
  customer_details: {
    address: IAddress;
  };
};

export const emptyResponse: TaxesResponse = {
  id: '',
  line_items: {
    data: [],
  },
  customer_details: {
    address: {
      country: '',
      city: '',
      postal_code: '',
      state: '',
      line1: '',
      line2: '',
    },
  },
};

export const getReferenceSuffix = (
  paymentType: components['schemas']['PaymentOption']['payment_type']
): string => (paymentType === PaymentType.one_time_fee ? 'otp_' : 'sub_');

const calculateTax = async (address: IAddress): Promise<TaxesResponse> => {
  const paymentPage = useOfferStore.getState().offer;
  const accSlug = paymentPage?.account.slug;
  const currency = paymentPage?.currency.code ?? CURRENCY_CODE.USD;

  const paymentOption = getSelectedPaymentOption();

  const live_mode = paymentPage?.status === 'live';
  const main = paymentPage?.product;
  const bump = paymentPage?.bumps[0];
  const paymentType = paymentOption?.payment_type;

  const otpPrices = usePageStore.getState().getOtpPrices();

  if (!otpPrices) {
    return emptyResponse;
  }

  const trialAmount = usePricesStore.getState().main.subscription.trial.amount;

  let lineItems: LineItem[] = [];

  const isMainTaxable = isTaxable(main?.product_tax_code);
  const isBumpTaxable = isTaxable(bump?.product?.product_tax_code);

  if (!isMainTaxable && !isBumpTaxable) {
    return emptyResponse;
  }

  if (main && paymentType) {
    lineItems.push({
      amount: +(
        otpPrices.mainUnitAmount - otpPrices.discountUnitAmount
      ).toFixed(),
      reference: `main_product_${getReferenceSuffix(paymentType)}${main.name}`,
      tax_code: main?.product_tax_code as string,
    });
  }

  if (main && Number(paymentOption?.trial_period_price) > 0) {
    lineItems.push({
      amount: +trialAmount.toFixed(),
      reference: `main_product_trial_${main.name}`,
      tax_code: main?.product_tax_code as string,
    });
  }

  if (otpPrices.bumpEnabled && otpPrices.bumpUnitAmount && bump?.product) {
    lineItems.push({
      amount: +otpPrices.bumpUnitAmount.toFixed(),
      reference: `bump_otp_${bump.product.name}`,
      tax_code: bump.product?.product_tax_code as string,
    });
  }

  if (!lineItems.length) {
    return emptyResponse;
  }

  const payload = {
    account: accSlug,
    integration_id: getStripePaymentIntegrationId(),
    live_mode,
    tax: {
      currency,
      customer_details: {
        address: {
          country: address.country,
          city: address.city,
          postal_code: address.postal_code,
          state: address.state,
          line1: address.line1,
          line2: address.line2 ?? '',
        },
        address_source: 'billing',
      },
      line_items: lineItems,
    },
  };

  const pageSlug = getPageSlug();

  return await getTaxAmounts(pageSlug, payload);
};

export default calculateTax;
