import { Offer } from '@/src/pages/[host]/[uid]/[slug]';
import { PurchasePayload } from '@/src/components/Checkout/Checkout.types';
import { PaymentType } from '@/src/components/Checkout/Checkout.enums';

type EventParams = {
  integrations: Offer['account']['integrations'] | null;
  paymentPage: Omit<Offer, 'account'>;
  purchasePayload?: PurchasePayload | null;
};

const triggerGtmAndFbEventsForStripePurchase = ({
  integrations,
  paymentPage,
  purchasePayload,
}: EventParams) => {
  const {
    bumps: [bump],
    payment_options: [payment_option],
    product,
  } = paymentPage;

  // FB Pixel events
  const fb = integrations?.find((i) => i.system_name === 'Facebook');
  if (typeof window.fbq === 'function' && fb?.options['events_enabled']) {
    const oneTime: Array<{
      id: number;
      quantity: number;
      price: number;
    }> = [];

    let oneTimePrice = 0;
    if (payment_option.payment_type === PaymentType.subscription) {
      const { price, trial_days, trial_period_price } = payment_option;
      if (+(trial_days ?? 0) > 0 && +(trial_period_price ?? 0) === 0)
        window.fbq('track', 'StartTrial', { value: price, currency: 'USD' });
      if (typeof trial_days !== 'undefined' && +trial_days === 0)
        window.fbq('track', 'Subscribe', { value: price, currency: 'USD' });
    } else {
      oneTimePrice += +(payment_option?.price ?? 0);
      oneTime.push({
        id: product.id,
        quantity: 1,
        price: payment_option.price!,
      });
    }
    if (purchasePayload?.active_bump) {
      const { payment_type, price, trial_days, trial_period_price } =
        bump.payment_option;
      if (payment_type === PaymentType.subscription) {
        if (+(trial_days ?? 0) > 0 && +(trial_period_price ?? 0) === 0)
          window.fbq('track', 'StartTrial', { value: price, currency: 'USD' });
        if (+(trial_days ?? 0) === 0)
          window.fbq('track', 'Subscribe', { value: price, currency: 'USD' });
      } else {
        oneTimePrice += +(price ?? 0);
        oneTime.push({
          id: bump.product.id,
          quantity: 1,
          price: price!,
        });
      }
    }
    if (oneTime.length > 0)
      window.fbq('track', 'Purchase', {
        value: oneTimePrice,
        currency: 'USD',
        contents: oneTime,
      });
  }

  // GTM events
  if (window.dataLayer) {
    const items = [
      {
        item_name: product.name,
        item_id: product.id,
        price: payment_option.price,
        quantity: 1,
      },
    ];
    if (purchasePayload?.active_bump) {
      items.push({
        item_name: bump.product.name,
        item_id: bump.product.id,
        price: bump.payment_option.price,
        quantity: 1,
      });
    }

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        currency: 'USD',
        voma_email: purchasePayload?.email,
        ...(purchasePayload?.['coupon_code'] && {
          coupon: purchasePayload?.['coupon_code'],
        }),
        items,
      },
    });
  }
};

export default triggerGtmAndFbEventsForStripePurchase;
