import { usePageStore } from "@/src/common/zustand";

export const getMainOtpTotal = (): number => {
  const { main_tax, bump_tax } =
    usePageStore.getState().taxCalcOTP;

  const otpPrices = usePageStore.getState().getOtpPrices();

  if (!otpPrices) {
    throw new Error('[processUpdatePaymentIntent] otpPrices null');
  }
  const { mainUnitAmount, bumpUnitAmount, discountUnitAmount, bumpSelected } =
    otpPrices;

  const subtotal =
    mainUnitAmount +
    (bumpSelected && bumpUnitAmount ? bumpUnitAmount : 0) -
    discountUnitAmount;
  const taxes = (main_tax ?? 0) + (bumpSelected && bump_tax ? bump_tax : 0);

  const total = subtotal + taxes;

  return total;
}