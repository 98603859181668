import { useOfferStore, usePageStore } from '@/src/common/zustand';

import {
  IContactInformation,
  OrderCustomFields,
  TCartSessionProduct,
} from './Checkout.types';

import { components } from '@/src/schemas/offer';
import { ActiveElementTypes, PaymentType } from './Checkout.enums';
import { PaymentWalletOption } from '@stripe/stripe-js';

export const createPurchaseHash = async (paymentPageId) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(Date.now() + '-' + paymentPageId);
  const buf = await crypto.subtle.digest('SHA-256', data);
  const purchase_hash = Array.prototype.map
    .call(new Uint8Array(buf), (x) => ('00' + x.toString(16)).slice(-2))
    .join('');

  return purchase_hash;
};

export const isFormValid = (
  addressEnabled: boolean,
  termsEnabled: boolean
): boolean => {
  const contactFormValid =
    usePageStore.getState().contactInformation?.isValid || false;
  const addressFormValid =
    usePageStore.getState().addressState?.isValid || false;
  const termsFormValid =
    usePageStore.getState().termsAndConditionsState?.isValid || false;

  return (
    contactFormValid &&
    (!termsEnabled || termsFormValid) &&
    (!addressEnabled || addressFormValid)
  );
};

export const getPageSlug = (): string => {
  const pathName = window.location.pathname.replace(new RegExp('/', 'g'), '-');

  const pageSlug = pathName.charAt(0) === '-' ? pathName.slice(1) : pathName;

  return pageSlug;
};

export const getName = (contactInfo: IContactInformation): string | null => {
  const firstName = contactInfo?.first_name
    ? contactInfo?.first_name.replace(/ .*/, '').trim()
    : '';

  const lastName = contactInfo?.last_name
    ? contactInfo?.last_name.replace(/ .*/, '').trim()
    : '';

  const nameCleaned = `${firstName || ''} ${lastName || ''}`.trim();

  if (contactInfo.first_name || contactInfo.last_name) {
    return nameCleaned;
  }

  return null;
};

export const getPaymentMethodTypes = (
  paymentMethods: components['schemas']['PaymentMethod'][]
): string[] => {
  if (!Array.isArray(paymentMethods)) {
    return [];
  }

  const paymentMethodTypes = paymentMethods.flatMap((paymentMethod) => {
    return typeof paymentMethod?.type === 'string' &&
      paymentMethod?.type?.length
      ? [paymentMethod.type]
      : [];
  });

  return paymentMethodTypes?.length ? paymentMethodTypes : [];
};

export const isAutomaticPaymentMethodsEnabled = (
  automaticPaymentMethods: boolean,
  paymentMethodTypes: string[]
): boolean => {
  return automaticPaymentMethods ||
    !Array.isArray(paymentMethodTypes) ||
    !paymentMethodTypes?.length
    ? true
    : false;
};

export const getOrderBumpDataFromDdbStore = (): TCartSessionProduct | null => {
  const { offer } = useOfferStore.getState();
  const paymentPageId = offer?.id;
  const bump = offer?.bumps[0];

  const orderBump =
    bump?.enabled && bump?.product && bump?.payment_option
      ? getProductData(paymentPageId, bump.product, bump.payment_option)
      : null;

  return orderBump;
};

export const getUpsellDataFromDdbStore = (): TCartSessionProduct | null => {
  const { offer } = useOfferStore.getState();
  const paymentPageId = offer?.id;
  const upsell = offer?.upsells[0] ?? null;

  const upsellData =
    upsell && upsell?.product && upsell?.payment_option
      ? getProductData(paymentPageId, upsell.product, upsell.payment_option)
      : null;

  return upsellData;
};

const getProductData = (
  paymentPageId: number | undefined,
  product: components['schemas']['Product'] | undefined,
  paymentOption: components['schemas']['PaymentOption'] | undefined
): TCartSessionProduct => {
  return {
    id: paymentPageId!,
    price: paymentOption?.price!,
    product_id: product?.id!,
    name: product?.name!,
    tax_behavior: product?.tax_behavior,
    recurring:
      paymentOption?.payment_type === PaymentType.subscription
        ? {
            frequency: paymentOption?.frequency as string,
            trial_days: Number(paymentOption?.trial_days),
            trial_period_price: Number(paymentOption?.trial_period_price),
            rebills: paymentOption?.rebills!,
          }
        : null,
  };
};

export const getWalletOption = (
  paymentMethods: components['schemas']['PaymentMethod'][] | null
): PaymentWalletOption => {
  const showWallets: boolean =
    !paymentMethods?.length ||
    !!paymentMethods.find((method) => method.type === ActiveElementTypes.Card);

  return showWallets ? 'auto' : 'never';
};

export const isCountrySupportedForWallet = (countryCode: string) => {
  if (!countryCode) {
    return false;
  }

  return [
    'AE',
    'AT',
    'AU',
    'BE',
    'BG',
    'BR',
    'CA',
    'CH',
    'CI',
    'CR',
    'CY',
    'CZ',
    'DE',
    'DK',
    'DO',
    'EE',
    'ES',
    'FI',
    'FR',
    'GB',
    'GI',
    'GR',
    'GT',
    'HK',
    'HR',
    'HU',
    'ID',
    'IE',
    'IN',
    'IT',
    'JP',
    'LI',
    'LT',
    'LU',
    'LV',
    'MT',
    'MX',
    'MY',
    'NL',
    'NO',
    'NZ',
    'PE',
    'PH',
    'PL',
    'PT',
    'RO',
    'SE',
    'SG',
    'SI',
    'SK',
    'SN',
    'TH',
    'TT',
    'US',
    'UY',
  ].includes(countryCode.toUpperCase());
};

export const isWallet = (activeElement: ActiveElementTypes | null) => {
  if (!activeElement) {
    return false;
  }

  return (
    !!activeElement &&
    [ActiveElementTypes.ApplePay, ActiveElementTypes.GooglePay].includes(
      activeElement
    )
  );
};

export const getAddress = () => {
  const address = usePageStore.getState().addressValues;

  // TODO: Determine what this does and improve readability accordingly.
  if (
    (address?.country?.length as number) > 1 &&
    !((address?.line1?.length as number) > 1)
  ) {
    return null;
  }

  return address;
};

export const getOrderCustomFieldsFromQuery = (
  params: URLSearchParams
): OrderCustomFields => {
  const customFields = {} as OrderCustomFields;

  const allowedFields = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    'via',
    'lmref',
    'lm_meta',
    'fpr',
    'http_referer',
  ];

  for (const [key, value] of params.entries()) {
    if (allowedFields.includes(key)) {
      customFields[key] = value;
    }
  }

  return customFields;
};
