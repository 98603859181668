import { Offer } from '@/src/pages/[host]/[uid]/[slug]';

export const isZeroDecimalCurrency = (
  currency: Offer['currency']['symbol']
) => {
  if (!currency) return false;
  // TODO: add full list of zero desimal currencies, use code instead of symbol
  // ['BIF', 'CLP', 'DJF', 'GNF', 'JPY', 'KMF', 'KRW', 'MGA', 'PYG', 'RWF', 'UGX', 'VND', 'VUV', 'XAF', 'XOF', 'XPF']
  return ['¥'].includes(currency);
};
