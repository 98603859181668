import { useOfferStore, usePageStore } from '@/src/common/zustand';

export const getSelectedPaymentOption = () => {
  const selectedPaymentOptionId =
    usePageStore.getState().selectedPaymentOptionId;

  const paymentOptions = useOfferStore.getState().offer?.payment_options;

  return (
    paymentOptions?.find((option) => option.id === selectedPaymentOptionId) ||
    paymentOptions?.find((option) => !!option.default) ||
    paymentOptions?.[0]
  );
};
