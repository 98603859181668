import { usePageStore, useOfferStore } from '@/src/common/zustand';

import { getOrderCustomFields } from './getOrderCustomFields';
import { getLmData } from './getLmData';
import { getRwReferral } from './getRwReferral';

import { components as PaymentComponents } from '@/src/schemas/payments';
import { GDPR } from '@/src/components/Checkout/Checkout.constants';

type Metadata = Omit<
  PaymentComponents['schemas']['StripeMetadata'],
  'purchase_hash' | 'recaptcha_score' | 'type'
>;

export const getMetadata = (): Metadata => {
  // Offer details from store
  const offer = useOfferStore.getState().offer!;

  // Bonuses
  const bonuses = offer?.bonuses;
  const deadlineExpired = usePageStore.getState().deadlineExpired;
  const bonusIds =
    deadlineExpired || !bonuses?.length
      ? []
      : bonuses.filter((bonus) => !!bonus?.id).map((bonus) => bonus.id);

  // URL parameters
  const params = getOrderCustomFields();

  // LinkMink
  const lm_data = getLmData();

  // Rewardful
  const rw_referral = getRwReferral();

  // GDPR
  const termsForm = usePageStore.getState().termsAndConditionsValues;
  const inEU = usePageStore.getState().inEU;
  const gdprEnabled = offer.customization?.gdpr?.visibility || false;

  // Password
  const contactInformation = usePageStore.getState().contactInformationValues;

  const password = contactInformation?.password;

  // Metadata
  const metadata = {
    v2: true,
    bonuses: bonusIds,
    gdpr_comply: Boolean(termsForm?.gdpr) ?? false,
    gdpr_in_eu: inEU,
    gdpr_visibility:
      gdprEnabled === GDPR.ALL || (gdprEnabled === GDPR.EU && inEU),
    ...(lm_data && { lm_data }),
    ...(rw_referral && { rw_referral }),
    ...params,
    ...(password && { password }),
  };

  return metadata;
};
