import { useOfferStore } from "@/src/common/zustand";

export const getLmData = (): string | null => {
  const offer = useOfferStore.getState().offer!;

  const lmApiKey = offer.account.integrations?.find((i) => i.system_name === 'Linkmink')?.credentials[
    'api_key'
  ] ?? null;

  let lm_data: string | null = null;

  if (lmApiKey && typeof window.LinkMink === 'function') {
    lm_data = window.LinkMink(lmApiKey).getReferralData();
  }

  return lm_data;
}