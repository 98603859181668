import { debounce } from 'lodash';

import { useOfferStore } from '../../zustand';

import { getPageSlug } from '@/src/components/Checkout/Checkout.utils';
import {
  getCleanEmail,
  isEmailValid,
} from '@/src/components/Deadline/Deadline.utils';

const sendCartAbandonmentRequestDebounce = debounce(
  (email) => {
    sendCartAbandonmentRequest(email);
  },
  3000,
  { leading: false, trailing: true }
);

const sendCartAbandonmentRequest = async (email: string): Promise<void> => {
  let cleanEmail = getCleanEmail(email);

  if (!isEmailValid(cleanEmail)) {
    return;
  }

  window.grecaptcha.ready(async () => {
    const reCaptchaToken = await window.grecaptcha.execute(
      process.env.RECAPTCHAV3_SITE_KEY,
      {
        action: 'enteremail',
      }
    );

    const offerId = useOfferStore.getState().offer?.id;
    const cookieName = getPageSlug();

    try {
      const response = await fetch('/api/cart-abandon', {
        method: 'POST',
        body: JSON.stringify({
          email: cleanEmail,
          offer_id: offerId,
          cookie_name: cookieName,
          recaptcha_token: reCaptchaToken,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();

      return data;
    } catch (error) {
      // console.log('Error in sendTaxCalculationRequest', error.message)
    }
  });
};

export default sendCartAbandonmentRequestDebounce;
