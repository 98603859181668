import { usePageStore } from '@/src/common/zustand';
import { getAddress } from '@/src/components/Checkout/Checkout.utils';
import { components as PaymentComponents } from '@/src/schemas/payments';
type Customer = PaymentComponents['schemas']['Customer'];

export const getCustomer = (): Customer => {
  // Contact form
  const contactInformation = usePageStore.getState().contactInformationValues;

  const contactForm = Object.fromEntries(
    Object.entries(contactInformation as object).filter(
      ([_, v]) => v != null && v != ''
    )
  );

  // Name
  const firstName = contactForm?.first_name
    ? contactForm?.first_name.replace(/ .*/, '').trim()
    : '';

  const lastName = contactForm?.last_name
    ? contactForm?.last_name.replace(/ .*/, '').trim()
    : '';

  const nameCleaned = `${firstName || ''} ${lastName || ''}`.trim();

  // Address
  const address = getAddress();

  return {
    email: contactForm.email,
    name: nameCleaned ?? null,
    phone: contactForm?.phone ?? null,
    tax_id: contactForm.tax_id ?? null,
    ...(address && {
      address: {
        city: address?.city,
        country: address?.country,
        line1: address?.line1,
        postal_code: address?.postal_code,
        state: address?.state,
      },
    }),
  };
};
