import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { Offer } from '@/src/pages/[host]/[uid]/[slug]';

export type OfferState = {
  offer: Offer | null;
  encryptedOffer: string | null;
};

const useOfferStore = create<OfferState>(
  persist(
    devtools(() => ({
      offer: null,
      encryptedOffer: null,
    })),
    {
      name: 'voma-offer-store',
      getStorage: () => sessionStorage,
    }
  )
);

export default useOfferStore;
