import { useOfferStore } from '@/src/common/zustand';

import { isZeroDecimalCurrency } from '@/src/helpers';
import { AddSubscriptionInvoiceItem, ItemType } from '../types';

export const getUpsellSubInvoiceItems = ():
  | AddSubscriptionInvoiceItem[]
  | [] => {
  let invoiceItems: AddSubscriptionInvoiceItem[] = [];

  const offer = useOfferStore.getState().offer!;

  const { payment_type, trial_days, trial_period_price } =
    offer.upsells[0].payment_option;

  // Upsell subscription paid trial
  const upsellSubscriptionHasPaidTrial =
    payment_type === 'subscription' &&
    trial_days &&
    trial_days > 0 &&
    trial_period_price &&
    trial_period_price > 0;

  if (upsellSubscriptionHasPaidTrial) {
    invoiceItems.push({
      price_data: {
        currency: offer.currency.code ?? 'USD',
        product: offer.upsells[0].product.stripe_id ?? '',
        unit_amount:
          trial_period_price! *
            (isZeroDecimalCurrency(offer?.currency.symbol) ? 1 : 100) ?? 0,
        metadata: {
          item_type: ItemType.Upsell,
        },
      },
    });
  }

  return invoiceItems;
};
