import { TaxesResponse } from './types';

export const CURRENCY_CODE = {
  USD: 'USD',
  EUR: 'EUR',
  GBP: 'GBP',
  CAD: 'CAD',
  SEK: 'SEK',
  TRY: 'TRY',
  AUD: 'AUD',
  NZD: 'NZD',
  JPY: 'JPY',
} as const;

export const EMPTY_RESPONSE: TaxesResponse = {
  id: '',
  line_items: {
    data: [],
  },
  customer_details: {
    address: {
      country: '',
      city: '',
      postal_code: '',
      state: '',
      line1: '',
      line2: '',
    },
  },
};
