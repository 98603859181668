export const getRwReferral = (): string | null => {
  let rw_referral: string | null = null;
  if (typeof window.rewardful === 'function') {
    window.rewardful('ready', function () {
      if (window.Rewardful.referral) {
        rw_referral = window.Rewardful.referral;
      }
    });
  }
  return rw_referral;
};
