export enum DiscountType {
  flat = 'flat',
  percentage = 'percentage',
}

export enum PaymentType {
  one_time_fee = 'one_time_fee',
  subscription = 'subscription',
}

export enum ActiveElementTypes {
  Card = 'card',
  GooglePay = 'google_pay',
  ApplePay = 'apple_pay',
  Affirm = 'affirm',
  Klarna = 'klarna',
  AfterPayClearPay = 'afterpay_clearpay',
  SepaDebit = 'sepa_debit',
  Ideal = 'ideal',
}
