import Cookies from 'js-cookie';
import {
  secondsInDay,
  secondsInHour,
  secondsInMinute,
} from 'date-fns/constants';
import {
  AfterDeadlineAction,
  DeadlineType,
  DeadlineUnit,
} from '@/src/components/Offer/Offer.enums';
import { useOfferStore } from '@/src/common/zustand';
import { verifyCoupon } from '@/src/common/api';
import { IDiscountData } from '../Checkout/Checkout.types';
import { isCouponApplicable } from '@/src/common/components/OrderSummary/utils';
import { getSelectedPaymentOption } from '@/src/services/PurchaseService/helpers';
import { CURRENCY_CODE, CURRENCY_SYMBOL } from '../Checkout/Checkout.constants';
import { isZeroDecimalCurrency } from '@/src/common/zustand/utils';

const COOKIE_LIFETIME_IN_DAYS = 30;

export type Time = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  totalSeconds: number;
};

const getTimePassed = (startTime: number): number =>
  Math.floor((Date.now() - Number(startTime)) / 1000);

const getLengthInSeconds = (unit: string, length: number) => {
  switch (unit) {
    case DeadlineUnit.Seconds:
      return length;
    case DeadlineUnit.Minutes:
      return length * secondsInMinute;
    case DeadlineUnit.Hours:
      return length * secondsInHour;
    case DeadlineUnit.Days:
      return length * secondsInDay;
    default:
      return length;
  }
};

export const getTripwireStartDate = (slug: string): number => {
  const cookieName = `voma-main_offer-deadline-${slug}`;
  const now = Date.now();

  const startDate = Cookies.get(cookieName);

  if (!startDate) {
    Cookies.set(cookieName, now, {
      expires: COOKIE_LIFETIME_IN_DAYS,
    });
  }

  return startDate ?? now;
};

export const calculateTripwireSeconds = (
  deadlineLength: number,
  unit: string,
  startTime: number | undefined
): number => {
  const timePassed = getTimePassed(startTime ?? 0);

  const lengthInSeconds = getLengthInSeconds(unit, deadlineLength || 0);

  return !!startTime
    ? timePassed > lengthInSeconds
      ? 0
      : lengthInSeconds - timePassed
    : lengthInSeconds;
};

// Calculate seconds left until deadline, using deadline date
// Used for evergreen and fixed deadlines
export const calculateSecondsByDate = (
  deadlineUtc: Date | undefined
): number => {
  if (!deadlineUtc) {
    return 0;
  }

  const now = Date.now();
  const deadlineTime = deadlineUtc?.getTime();
  const localDeadlineTime =
    deadlineTime - new Date().getTimezoneOffset() * 60 * 1000;

  if (localDeadlineTime < now) {
    return 0;
  }

  return Math.floor(Math.abs((localDeadlineTime - now) / 1000));
};

export const getTime = (totalSeconds: number): Time => {
  return {
    days: Math.floor(totalSeconds / 86400),
    hours: Math.floor((totalSeconds % 86400) / 3600),
    minutes: Math.floor(((totalSeconds % 86400) % 3600) / 60),
    seconds: ((totalSeconds % 86400) % 3600) % 60,
    totalSeconds,
  };
};

export const applyAutomaticDiscount = async (): Promise<
  IDiscountData | undefined
> => {
  const deadlineEnabled =
    useOfferStore.getState().offer?.deadline?.main_offer?.enabled;

  const offerStack =
    useOfferStore.getState().offer?.deadline?.main_offer?.type || null;

  // If deadline enabled, discount will be applied through applyOfferStackDiscount
  // If deadline not enabled but something other than None selected, then discount won't be applied.
  if (deadlineEnabled || offerStack !== 'none') {
    return;
  }

  return await applyOfferStackDiscount(true);
};

export const applyOfferStackDiscount = async (
  automatic: boolean = false
): Promise<IDiscountData | undefined> => {
  const deadlineEnabled =
    useOfferStore.getState().offer?.deadline?.main_offer?.enabled;

  const offerStackDiscount =
    useOfferStore.getState().offer?.deadline?.main_offer?.discount?.type ?? '';

  const discountEnabled = ['percentage', 'flat'].includes(offerStackDiscount);

  const paymentOption = getSelectedPaymentOption();
  const mainAmount = paymentOption?.unit_amount;

  const paymentPage = useOfferStore.getState().offer;

  const currencySymbol = paymentPage?.currency.symbol ?? CURRENCY_SYMBOL.USD;
  const zeroDecimal = isZeroDecimalCurrency(currencySymbol);
  const currency = paymentPage?.currency.code ?? CURRENCY_CODE.USD;

  const paymentPageId = useOfferStore.getState().offer?.id;
  const productId = useOfferStore.getState().offer?.product?.id ?? null;
  const bumpId =
    useOfferStore.getState().offer?.bumps?.[0]?.product?.id ?? null;
  const accountSlug = useOfferStore.getState().offer?.account?.slug ?? null;

  const couponBody = {
    paymentPageId,
    productId,
    bumpId,
  };

  if (
    !accountSlug ||
    !paymentPageId ||
    !productId ||
    // Automatic discount should be applied regardless of deadline being enabled or not
    (!automatic && !deadlineEnabled) ||
    !discountEnabled
  ) {
    return;
  }

  const res = await verifyCoupon(
    accountSlug,
    `OS-${paymentPageId}-DISCOUNT`,
    couponBody
  );

  const isApplicable = isCouponApplicable(
    res,
    mainAmount!,
    zeroDecimal,
    currency
  );

  if (!isApplicable) {
    return;
  }

  return res;
};

export const triggerAfterDeadline = () => {
  const deadlineEnabled =
    useOfferStore.getState().offer?.deadline?.main_offer?.enabled || false;

  const offerStack =
    useOfferStore.getState().offer?.deadline?.main_offer?.type || null;

  const after =
    useOfferStore.getState().offer?.deadline?.after_deadline || null;

  if (!deadlineEnabled || offerStack === DeadlineType.OptinToOffer) {
    return;
  }

  if (
    after?.action === AfterDeadlineAction.RedirectToUrl &&
    after?.external_url
  ) {
    window.location.assign(after?.external_url);
  }

  if (
    after?.action === AfterDeadlineAction.RedirectToOffer &&
    after?.offer_slug
  ) {
    window.location.assign(after?.offer_slug);
  }
};

export const isDeadlineExpired = (deadlineUtc: string): boolean => {
  // Compare deadlineUtc to current time, return true if deadline is expired
  const now = Date.now();
  const deadlineTime = new Date(deadlineUtc).getTime();
  const localDeadlineTime =
    deadlineTime - new Date().getTimezoneOffset() * 60 * 1000;
  return localDeadlineTime < now;
};
