// These are values that will not be persisted by Zustand to session storage
// https://docs.pmnd.rs/zustand/integrations/persisting-store-data

const shouldNotPersist = ([key]): boolean =>
  [
    'contactInformation',
    'addressState',
    'termsAndConditionsState',
    'termsAndConditionsValues',
    'triggerFormValidation',
    'triggerCreateStripeToken',
    'isProcessingPayment',
    'stripeToken',
    'selectedSubscriptionId',
    'bumpSelected',
    'showUpsell',
    'showThankyouPage',
    'showPreloader',
    'previewMode',
    'discountCode',
    'discount',
    'discountProducts',
    'couponState',
    'paymentElementCustomerId',
    'paymentElementClientSecret',
    'paymentElementPaymentIntentId',
    'paymentElementSubscriptionId',
    'paymentElementPaymentResult',
    'paymentElementPaymentError',
    'paymentElementPaymentLoading',
    'paymentElementSetupIntentId',
    'selectedPaymentOptionId',
    'currency',
    'deadlineInfo',
    'deadlineExpired',
    'taxCalcOTP',
    'lastTaxCalcRequestAddress',
  ].includes(key);

export default shouldNotPersist;
