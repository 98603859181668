import { isTaxable } from '../services/TaxService/helpers';
import { useOfferStore } from '../common/zustand';

import { isUpsellEnabled } from './isUpsellEnabled';

export const isUpsellTaxable = () =>
  isUpsellEnabled() &&
  isTaxable(
    useOfferStore.getState().offer?.upsells[0]?.product?.product_tax_code
  );
