const isZeroDecimalCurrency = (currencyCode: string) => {
  return [
    'BIF',
    'CLP',
    'DJF',
    'GNF',
    'JPY',
    'KMF',
    'KRW',
    'MGA',
    'PYG',
    'RWF',
    'UGX',
    'VND',
    'VUV',
    'XAF',
    'XOF',
    'XPF',
  ].includes(currencyCode);
};

export default isZeroDecimalCurrency;
